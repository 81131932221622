/* eslint-disable complexity */
import React, {ReactNode, useEffect, useState} from "react"
import Link from "next/link"

export enum Color {
  white = "light",
  black = "dark",
  orange = "primary",
  grey = "light",
  transparent = "transparent"
}

type Props = {
  label?: string
  className?: string
  href?: string
  style?: React.CSSProperties
  disabled?: boolean
  active?: boolean
  borderWidth?: number
  isLoading?: boolean
  onClick?: (arg?: any) => void
  fontSizeSmall?: boolean
  outline?: boolean
  color?: keyof typeof Color | string
  textColor?: keyof typeof Color | string
  bgColor?: keyof typeof Color | string
  hoverColor?: keyof typeof Color | string
  spinnerColor?: keyof typeof Color | string
  borderColor?: keyof typeof Color | string
  high?: boolean
  type?: "button" | "reset" | "submit"
  icon?: React.ReactNode
  iconOnHover?: React.ReactNode
  padding?: string,
  paddingX?: string,
  paddingY?: string,
  noWrap?: boolean,
  semanticTagA?: boolean,
  square?: boolean,
  target?: string,
  children?: ReactNode,
  isNoUppercase?: boolean
  opacity?: number
	borderRadius?: number
	iconFirst?: boolean
	btnFontSize?:number

}

const Button = ({
	square,
	target = "_self",
	semanticTagA,
	label,
	href,
	isLoading,
	style,
	noWrap,
	onClick,
	className,
	fontSizeSmall,
	icon,
	iconOnHover,
	borderColor,
	textColor = "#212529",
	paddingX = "4",
	paddingY = "2",
	type = "button",
	high,
	borderWidth,
	color = "#fff",
	spinnerColor = "#212529",
	outline,
	active,
	disabled,
	children = null,
	hoverColor,
	bgColor,
	isNoUppercase = false,
	borderRadius,
	opacity = 0.8,
	iconFirst,
	btnFontSize

}: Props): React.ReactElement => {
	const classes = `btn ${disabled ? "btn-disabled" : ""} ${outline ? `btn-outline-${Color[color]} border-${borderWidth}` : `btn-${Color[color]} border-0`}  px-${paddingX} py-${paddingY} ${className ? className : ""}`
	const Tag = href ? "a" : "button"
	const [isHover, setIsHover] = useState(false)

	const handleClick = e => {
		// E.preventDefault()
		// e.stopPropagation()
		if (onClick) {
			onClick(e)
		}
	}

	useEffect(() => {
		setIsHover(false)
	}, [])

	const handleMouseEnter = () => {
		setIsHover(true)
	}

	const handleMouseLeave = () => {
		setIsHover(false)
	}

	const button = <Tag type={href ? null : type} target={target} className={classes}
		style={{
			border: color ? `1px solid ${borderColor ? borderColor : textColor}` : "",
			backgroundColor: isHover && hoverColor ? hoverColor : bgColor,
			opacity: isHover && !hoverColor ? opacity : 1,
			borderRadius: borderRadius ? borderRadius : 4
		}}
		onClick={handleClick} disabled={disabled}
		onMouseEnter={handleMouseEnter}
		onMouseLeave={handleMouseLeave}
	>
		<div className={"d-flex align-items-center justify-content-center"}>
			{isLoading && <div className={"col-auto me-2"}>
				<div className={"spinner-border spinner-border-sm"} style={{color: spinnerColor}} role="status">
					<span className="visually-hidden">Loading...</span>
				</div>
			</div>}
			{iconFirst && icon && isHover && iconOnHover ? <div className={"col-auto me-2 justify-content-start d-flex align-items-center"}>
				{iconOnHover}
			</div> : iconFirst && icon ? <div className={"col-auto me-2 d-flex justify-content-start align-items-center"}>
				{icon}
			</div> : null}
			<div
				style={{
					fontWeight: 900,
					fontSize: btnFontSize ? btnFontSize : fontSizeSmall ? 14 : 20,
					padding: `${paddingX ? paddingX : 8} ${paddingY ? paddingY : 40}`,
					color: isHover ? color : textColor
				}}
				className={`col-auto d-flex align-items-center ${isNoUppercase ? "" : "text-uppercase"} ${noWrap ? "text-nowrap" : "text-wrap"}`}>
				{children || label}
			</div>
			{!iconFirst && icon && isHover && iconOnHover ? <div className={"col-auto mx-2 me-2"}>
				{iconOnHover}
			</div> : !iconFirst && icon ? <div className={"col-auto mx-2 me-2 d-flex align-items-center"}>
				{icon}
			</div> : null}
		</div>
	</Tag>

	return href ?
		<Link href={href}>{button}</Link> : button
}

export default Button
