/* eslint-disable radix */
import React, {useContext, useEffect, useRef, useState} from "react"
import {useTranslation} from "next-i18next"
import {StockStatus} from "../../bikeDetails/BikeDetails"
import LayoutContext from "../../../context/LayoutContext"
import fetchVariations from "../../../fetch/FetchVariations"
import useOnScreen from "../../../hooks/OnScreen"
import {findBikeAttributeByName, findBikeMetaFieldByName, isCategory} from "../../../utility/Helper"
import PropTypes from "prop-types"
import CollectionItemGridView from "./CollectionItemGridView"
import CollectionItemListView from "./CollectionItemListView"
import {getDiscountData} from "../../../config/discountsConfig"
import DataContext from "../../../context/DataContext"

type Props = {
	bike: any,
	occasion?: boolean
	listView?: boolean
	isRowForMobile?: boolean
	cardWidth?: string
	isTwoCardsView?: boolean
	isInsideSlider?: boolean,
	withArrows?:boolean
	isCarouselElement?:boolean
	withOneImage?: boolean
	isWishList?: boolean
}

const BikesCollectionItem: React.FC<Props> = ({
	bike,
	occasion,
	listView,
	isRowForMobile,
	cardWidth,
	isTwoCardsView = false,
	withArrows,
	isWishList = false,
	isCarouselElement,
	withOneImage
}) => {
	const {t} = useTranslation("common")
	const [selected, setSelected] = useState<boolean>(false)
	const [loadingVariations, setLoadingVariations] = useState<boolean>(false)
	const [variations, setVariations] = useState<any>(null)
	const [loading] = useState(false)
	const [dImageSlides, setDImageSlides] = useState<boolean>(true)
	const {isMobile} = useContext(LayoutContext)
	const ref = useRef<HTMLDivElement>()
	const onScreen = useOnScreen(ref)
	const attachments = findBikeAttributeByName(bike, "Attachments", false, false)
	const mainCategory = bike?.main_category?.name
	const brand = bike?.attributes.find((e:any) => e.name === "Marke")?.options[0]
	const soldOut = bike.stock_status === StockStatus.outOfStock
	const stockInfo = bike.stock_status === StockStatus.inStock ? t("in-stock") : ""
	const isInStock = bike.stock_status === StockStatus.inStock
	const productUrl = `/${t("produkt")}/${bike.slug}`

	const [timeout, storeTimeout] = useState(null)

	// Sale related variables
	const {isOnSale: onSale, discountKey} = getDiscountData(bike)

	const regularPrice = findBikeMetaFieldByName(bike, "regular_price")
	const kilometers = findBikeMetaFieldByName(bike, "kms")
	const isNew = isCategory(bike, "New")
	const isFeatured = isCategory(bike, "Featured PLP") || false
	const is2022 = isCategory(bike, "2022")
	const is2023 = isCategory(bike, "2023")
	const isNonEBike = isCategory(bike, "Bikes")
	const isBikeOfTheMonth = isCategory(bike, "E-Bike of the Month")
	const bikeWeightStr = bike.attributes.filter((attr:any) => attr.name === "Max. Gewicht")[0]?.options[0]?.split("k")[0]
	const bikeWeight = parseInt(bikeWeightStr)
	const secondHandPrice = Number(findBikeMetaFieldByName(bike, "secondhand_price"))
	const {customBikeRecommendations} = useContext(DataContext)
	const isRecommended = customBikeRecommendations.some(recommendation => recommendation.slug === bike.slug)
	const handleMouseEnter = () => {
		if (!soldOut) {
			setSelected(true)
		}
	}

	const handleMouseLeave = () => {
		if (!soldOut) {
			setSelected(false)
		}
	}

	useEffect(() => {
		if (selected === true && !variations) {
			storeTimeout(setTimeout(() => {
				setLoadingVariations(true)
				fetchVariations(bike.id).then(response => {
					setLoadingVariations(false)
					setVariations(response)
				})
			}, isMobile ? 3000 : 1500))
		} else if (selected !== true && timeout) {
			clearTimeout(timeout)
		}
	}, [selected])

	useEffect(() => {
		if (isMobile) {
			setSelected(onScreen)
		}
	}, [onScreen])

	return (
		listView ?
			<CollectionItemListView
				secondHandPrice={secondHandPrice}
				attachments={attachments}
				bike={bike}
				bikeWeight={bikeWeight}
				brand={brand}
				badgeProps={{isInStock, isBikeOfTheMonth, isFeatured, is2023, is2022, isNew, occasion, kilometers, onSale, isNonEBike, discountKey}}
				mainCategory={mainCategory}
				dImageSlides={dImageSlides}
				handleMouseEnter={handleMouseEnter}
				handleMouseLeave={handleMouseLeave}
				loading={loading}
				loadingVariations={loadingVariations}
				productUrl={productUrl}
				regularPrice={regularPrice}
				selected={selected}
				setDImageSlides={setDImageSlides}
				soldOut={soldOut}
				stockInfo={stockInfo}
				variations={variations}
				ref={ref}
				withArrows={withArrows}
			/> :
			<CollectionItemGridView
				isRowForMobile={isRowForMobile}
				secondHandPrice={secondHandPrice}
				attachments={attachments}
				bike={bike}
				isRecommended={isRecommended}
				bikeWeight={bikeWeight}
				brand={brand}
				isWishList={isWishList}
				badgeProps={{isInStock, isBikeOfTheMonth, isFeatured, is2023, is2022, isNew, occasion, kilometers, onSale, isNonEBike, discountKey}}
				mainCategory={mainCategory}
				dImageSlides={dImageSlides}
				handleMouseEnter={handleMouseEnter}
				handleMouseLeave={handleMouseLeave}
				loading={loading}
				isCarouselElement={isCarouselElement}
				loadingVariations={loadingVariations}
				productUrl={productUrl}
				regularPrice={regularPrice}
				selected={selected}
				setDImageSlides={setDImageSlides}
				soldOut={soldOut}
				stockInfo={stockInfo}
				variations={variations}
				ref={ref}
				cardWidth={cardWidth}
				isTwoCardsView={isTwoCardsView}
				withArrows={withArrows}
				withOneImage={withOneImage}
			/>
	)
}

BikesCollectionItem.propTypes = {
	bike: PropTypes.any.isRequired,
	occasion: PropTypes.bool,
	listView: PropTypes.bool
}

export default BikesCollectionItem
