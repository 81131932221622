import Typography from "../../reusable/Typography/Typography"
import React from "react"
import {ExclamationCircle} from "react-bootstrap-icons"
import Button from "../../reusable/components/Button"
import {useTranslation} from "next-i18next"
import Image from "next/image"
import {content} from "../../../constants/Instances"
import usePrice from "../../../hooks/usePrice"

type Props = {
	bikeId?: any
	isSecondHand: boolean
	disabled?: boolean
	product?:any
	isLoading?: boolean
	secondHandPrice?: number
	handleClick?: any
	isAccessory?: boolean
	handleSizeClick?: any
	initialPrice?: number
	planLength?: any
	isOnSale?: boolean
	checkoutData?: any
	customInstanceDiscount?: number
}

const PriceTooltipWithCheckoutBtn: React.FC<Props> = ({isSecondHand, isOnSale, product, customInstanceDiscount, initialPrice, secondHandPrice, disabled, bikeId, planLength, isLoading, handleClick, checkoutData, handleSizeClick}) => {
	const {t} = useTranslation()
	const {finalPriceWithDownPayment, finalPriceWithoutDownPayment} = usePrice(product)

	return (
		<div className="col-12 mt-4 pt-1">
			{customInstanceDiscount &&
				<div className="col-12 text-white downpayment-price-tooltip position-relative d-flex gap-2 align-items-center justify-content-between">
					<div className="d-flex align-items-center">
						<div className="d-none d-md-flex align-items-center">
							<Image src={process.env.NEXT_PUBLIC_CUSTOM_INSTANCE ? content[process.env.NEXT_PUBLIC_CUSTOM_INSTANCE]?.companyIcon : null} className="d-none d-md-block" height={24} width={40}/>
						</div>
						<div className="d-flex d-md-none align-items-center">
							<Image src={process.env.NEXT_PUBLIC_CUSTOM_INSTANCE ? content[process.env.NEXT_PUBLIC_CUSTOM_INSTANCE]?.companyIcon : null} className="d-block d-md-none" height={12} width={20}/>
						</div>
						<Typography variant="bodySmBold" className="ms-2 d-none d-md-block" semanticTag="span">{process.env.NEXT_PUBLIC_CUSTOM_INSTANCE ? content[process.env.NEXT_PUBLIC_CUSTOM_INSTANCE]?.companyName : null} Exclusive price</Typography>
						<Typography variant="bodyXSmBold" className="ms-2 d-md-none" semanticTag="span">{process.env.NEXT_PUBLIC_CUSTOM_INSTANCE ? content[process.env.NEXT_PUBLIC_CUSTOM_INSTANCE]?.companyName : null} Exclusive price</Typography>
					</div>
					<Typography variant="bodySmBold" semanticTag="span" className="ms-2 d-none d-md-block">-{customInstanceDiscount} CHF</Typography>
					<Typography variant="bodyXSmBold" semanticTag="span" className="ms-2 d-md-none">-{customInstanceDiscount} CHF</Typography>
				</div>}
			<div className="d-flex align-items-center justify-content-between" style={{marginTop: 12}}>
				<div className="col-auto position-relative">
					{checkoutData?.planLength > 1 ?
						<div className="col-auto" style={{color: "#E61C45"}}>
							<Typography variant={"bodySmBold"} semanticTag="span">CHF</Typography>
							<Typography variant={"heading1Black"} className="ms-1" semanticTag="span" style={{fontSize: 36, lineHeight: 1.3}}>
								{`${Math.ceil(finalPriceWithDownPayment / (planLength))}`}
							</Typography>
							<Typography variant={"bodySmBold"} semanticTag="span" className="ms-1">
								{`${t("pro Monat")}`}
							</Typography>
							{!isSecondHand && (
								<div style={{color: "#6C7074"}}>
									<Typography variant="bodySmBold" semanticTag="div" className={"text-start col-12 text-capitalize"} style={{color: "#E61C45"}}>
										{`${t("Gesamtpreis")}:`}
									</Typography>
									<Typography variant={"bodySm"} semanticTag="span" style={{color: "#E61C45"}}>
										{finalPriceWithoutDownPayment}
									</Typography><Typography variant={"bodySm"} semanticTag="span" className="ms-1 text-decoration-line-through">
										{initialPrice}
									</Typography>
									<Typography variant={"bodySm"} semanticTag="span" className="ms-1">
										(UVP)
									</Typography>
									<Typography variant={"bodyXSm"} semanticTag="div">
										Inklusive MwSt.
									</Typography>
								</div>)
							}
						</div> :
						<div className="col-auto">
							<Typography variant={"heading1Black"} semanticTag="span">CHF</Typography>
							<Typography variant={"heading1Black"} className="ms-1" semanticTag="span" style={{fontSize: 36}}>
								{finalPriceWithDownPayment}
							</Typography>
						</div>}
				</div>
				<div className="col-6 col-md-6 d-flex justify-content-end">
					{disabled ?
						<a onClick={handleSizeClick}
							href="#size"
							style={{backgroundColor: "#777777", height: 64}}
							className="text-decoration-none text-white col-12 col-md-12 d-flex justify-content-center align-items-center rounded p-3">
							<div className="col-auto">
								<ExclamationCircle size={16}/>
							</div>
							<span style={{fontSize: 14}} className="text-bold text-uppercase ms-2">
								{t("fixed-btn-select-size", {ns: "productDescrPage"})}
							</span>
						</a>						:
						<>
							<div className="d-none d-md-block">
								<Button bgColor="#FEC04D" textColor="#212529" color="#212529" className="w-100" spinnerColor="white"
									label={t("shop")}
									paddingY={"3"}
									disabled={disabled} isLoading={isLoading}
									onClick={handleClick}
								/>
							</div>
							<div className="d-md-none col-10">
								<Button bgColor="#FEC04D" textColor="#212529" color="#212529" className="w-100" spinnerColor="white"
									label={t("shop")}
									fontSizeSmall
									paddingX={"3"}
									disabled={disabled} isLoading={isLoading}
									onClick={handleClick}
								/>
							</div>
						</>
					}
				</div>
			</div>
		</div>
	)
}

export default PriceTooltipWithCheckoutBtn
