const path = require("path")

module.exports = {
	i18n: {
		defaultNS: "common",
		defaultLocale: "de",
		locales: ["de"],
		localePath: path.resolve("./public/locales"),
		saveMissing: false,
		ns: ["sponsorship", "serviceCenters", "customerquestionform", "warningcart", "insurance", "veloAfrica", "common", "banners", "kundendienst", "homeDetails", "urbanContent", "kalkhoff", "fischer", "benno", "checkout", "PDPTiles", "consultationPage", "homePage", "referralPage", "productDescrPage", "black-friday", "ruffCycles", "haibike", "benno", "stromer", "filters", "returnPolicyPage", "accessoriesPage", "aboutUs", "URL", "search", "verifyEmail", "secondHandBikes", "auth", "partnership"],
		debug: false,
		keySeparator: "####",
		nsSeparator: "##",
		preload: [
			"de"
		]
	},
	backend: {
		loadPath: "./public/locales/{{lng}}/{{ns}}.json",
		addPath: "./public/locales/{{lng}}/{{ns}}.json"
	}
}
