import {Field, useFormikContext} from "formik"
import {useTranslation} from "next-i18next"
import React from "react"

interface Props {
    name: string
    children: React.ReactNode
}

const CheckBoxField:React.FC<Props> = ({name, children}) => {
	const {setFieldValue, errors, touched} = useFormikContext<any>()
	const {t} = useTranslation()
	return (
		<div className="mt-2 container-radio pointer">
			<div>
				<label className="data-cypress-personal-data-checkbox">
					<Field type="checkbox" name={name} ></Field>
					{/* <input type="checkbox" name="heidipay_agb"></input> */}
					<span className="radio radio-sqre pointer"></span>
					{children}
				</label>
				{/* {errors[name] && touched[name] ? (
					<div style={{color: "red"}}>{}</div>
				) : null} */}
			</div>
		</div>
	)
}

export default CheckBoxField
