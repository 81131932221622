export const Collections = {
	wishLists: `userWishLists-${process.env.NEXT_PUBLIC_STOREFRONT}`,
	sponsorMe: `sponsorMe-${process.env.NEXT_PUBLIC_STOREFRONT}`,
	productQuestions: "productQuestions",
	admins: "admins",
	stockBroker: "stockBroker",
	sponsorMePayments: `sponsorMePayments-${process.env.NEXT_PUBLIC_STOREFRONT}`,
	checkOuts: `checkouts-${process.env.NEXT_PUBLIC_STOREFRONT}`,
	contentfulPageData: "contentfulPageData",
	magicDocs: "magicDocs",
	userOrganizations: "userOrganizations"
}
