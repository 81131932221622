import React from "react"

export type CartItem = {
    customInstanceDiscount: number
    product: any,
    selectedSize: string,
    uniqueId: string,
	selectedVariationId: number,
	selectedAddOn: string // SKU of an add-on product,
	downPayment: number | null
	isOnSale?: boolean
	initialPrice?: number | string
	isSponsored?: boolean
	adminToken?: string
	token?: string
	numberOfItems?: number
}

export type CartContextState = {
    items: CartItem[],
    // eslint-disable-next-line no-unused-vars
    setItems: (items: (prevItems: CartItem[]) => CartItem[]) => void
    // eslint-disable-next-line no-unused-vars
    addItem: (item: CartItem) => void
    // eslint-disable-next-line no-unused-vars
    removeItem: (item: CartItem) => void
    // eslint-disable-next-line no-unused-vars
    storeCart: (items: CartItem[]) => void
	// eslint-disable-next-line no-unused-vars
	updateItem: (item: CartItem, updatedItem: CartItem) => void
	clearCart: () => void
}

const CartContext = React.createContext<CartContextState>({
	items: null,
	setItems: () => {
		// Do nothing
	},
	addItem: () => {
		// Do nothing
	},
	removeItem: () => {
		// Do nothing
	},
	updateItem: () => {
		// Do nothing
	},
	storeCart: () => {
		// Do nothing
	},
	clearCart: () => {
		// Do nothing
	}
})

export default CartContext
