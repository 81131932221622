export type Category = {
    id: number
    name: string
    slug: string
}

const mainCategories = [
	{
		name: "Add-ons",
		slug: "add-ons"
	},
	{
		name: "Sofas & Sessel",
		slug: "sofas,sessel"
	},
	{
		name: "Table & Chairs",
		slug: "tische,stuhle"
	},
	{
		name: "Stuhle",
		slug: "stuhle"
	},
	{
		name: "Betten",
		slug: "betten"
	},
	{
		name: "Aufbewahrung",
		slug: "aufbewahrung"
	},
	{
		name: "Dekoration",
		slug: "dekoration"
	},
	{
		name: "Outdoor",
		slug: "balkon-and-garten"
	}
]
const getMainCategory = categories => {
	for (let i = 0; i < mainCategories.length; i++) {
		const _mainCategory = categories.find(e => e.slug === mainCategories[i].slug)
		if (_mainCategory) {
			return _mainCategory
		}
	}

	return null
}

export default getMainCategory
