import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import OrganizationContext from "../context/OrganizationContext"

type Props = {
    children: React.ReactNode
}

const OrganizationContextProvider: React.FC<Props> = ({children}) => {
	const [organizationSlug, setOrganizationSlug] = useState<string | null>(null)
	const [organizationInitialized, setOrganizationInitialized] = useState<boolean>(false)

	useEffect(() => {
		setOrganizationSlug(process.env.NEXT_PUBLIC_CUSTOM_INSTANCE ? window.location.host.split(".")[0] : null)
		setOrganizationInitialized(true)
	}, [])

	return (
		<OrganizationContext.Provider value={{organizationInitialized, organizationSlug}}>
			{children}
		</OrganizationContext.Provider>
	)
}

OrganizationContextProvider.propTypes = {
	children: PropTypes.node
}

export default OrganizationContextProvider
