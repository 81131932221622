import React, {useEffect, useMemo, useState} from "react"
import PropTypes from "prop-types"
import DataContext from "../context/DataContext"
import {getAccessoriesCountByOption, getBikesCountByOption} from "../utility/helpers"
import {useRouter} from "next/router"
import {createRecommendationsList} from "../utility/Helper"
import {BikeType} from "../utility/Types"

type Props = {
    children: React.ReactNode
	pageProps: any
}

const sessionStorageItemName = "comparisonList"

const storeComparison = (items: string[]) => {
	if (typeof window !== undefined) {
		sessionStorage.setItem(sessionStorageItemName, JSON.stringify(items))
	}
}

const getComparison = () => {
	if (typeof window !== undefined) {
		const list = sessionStorage.getItem(sessionStorageItemName)
		if (list !== null) {
			return JSON.parse(list)
		}

		return []
	}

	return []
}

const DataContextProvider: React.FC<Props> = ({children, pageProps}) => {
	const {bikesList: bikes, accessoriesList, isCollectionOrBrandPage, bikeFilters, accessoryFilters, productsCount, deals, productsPriceRange} = pageProps
	const {asPath} = useRouter()
	const [organization, setOrganization] = useState<string | null>(null)
	const [data, setData] = useState<any>(null)
	const [collectionBikes, setCollectionBikes] = useState<any[] | null>(pageProps.bikesList)
	const [accessories, setAccessories] = useState<any[] | null>(accessoriesList)
	const [collections, setCollections] = useState<any[] | null>(pageProps.collections)
	const [localisedSlugs] = useState<any[] | null>(pageProps.localisedSlugs)
	const [brands, setBrands] = useState<any[] | null>(pageProps.brands)
	const [eventListeners, setEventListeners] = useState(null)
	const [productHSList, setProductHSList] = useState<any[]>(null)
	const accessoriesCountByOption = useMemo(() => getAccessoriesCountByOption(accessoriesList), [accessoriesList])
	const [customBikeRecommendations, setCustomBikeRecommendations] = useState<BikeType[]>([])
	const [comparisonProducts, setComparisonProducts] = useState<string[]>([])
	const maxAmountBlackFridayBikes = 300
	const soldAmountBlackFridayBikes = 150
	const [isInitialized, setIsInitialized] = useState<boolean>(false)

	const bikesCountByOption = useMemo(() => {
		return getBikesCountByOption(isCollectionOrBrandPage ? collectionBikes : bikes)
	}, [bikes, collectionBikes, isCollectionOrBrandPage])
	const _asPath = asPath.split("?")[0]
	const isBlackFriday = _asPath === "/black-friday"

	const handleRecommendations = () => {
		const recentlyViewedBikes = JSON.parse(localStorage.getItem("recentlyViewedProducts"))
		if (recentlyViewedBikes?.length) {
			const results = createRecommendationsList(recentlyViewedBikes, customBikeRecommendations)
			setCustomBikeRecommendations(() => results.filter(item => item?.uvpPrice))
		}
	}

	useEffect(() => {
		handleRecommendations()
		setIsInitialized(true)
		setOrganization(process.env.NEXT_PUBLIC_CUSTOM_INSTANCE ? window.location.host.split(".")[0] : null)
		setComparisonProducts(getComparison())
	}, [])

	useEffect(() => {
		if (typeof window !== "undefined") {
			window.addEventListener("RecentlyViewedUpdated", handleRecommendations)
		}

		return () => window.removeEventListener("RecentlyViewedUpdated", handleRecommendations)
	}, [])

	useEffect(() => {
		if (isInitialized) {
			storeComparison(comparisonProducts)
		}
	}, [comparisonProducts])

	return (
		<DataContext.Provider value={{customBikeRecommendations, setCustomBikeRecommendations, organization, productsCount, setProductHSList, productHSList, setComparisonProducts, comparisonProducts, maxAmountBlackFridayBikes, soldAmountBlackFridayBikes, isCollectionOrBrandPage, data, setData, bikes, accessories, setAccessories, accessoriesCountByOption, bikesCountByOption, collections, setCollections, brands, setBrands, localisedSlugs, collectionBikes, setCollectionBikes, pageProps, isBlackFriday, eventListeners, setEventListeners, bikeFilters, accessoryFilters, deals, productsPriceRange}}>
			{children}
		</DataContext.Provider>
	)
}

DataContextProvider.propTypes = {
	children: PropTypes.node,
	pageProps: PropTypes.any
}

export default DataContextProvider
