import React, {Dispatch, SetStateAction} from "react"
import Typography from "../reusable/Typography/Typography"
import Button from "../reusable/components/Button"
import {colors} from "../../colors/Colors"
import {useTranslation} from "next-i18next"

type Prop = {
    setModalOpen: Dispatch<SetStateAction<boolean>>
}
function SponsorMeSection({setModalOpen}: Prop) {
	const {t} = useTranslation("sponsorship")
	return (
		<div className="d-flex flex-column px-2 pb-2">
			<Typography variant="bodySmBold" className="text-secondary" semanticTag="p">
				{t("sponsorship-title")}
			</Typography>
			<div className="d-flex flex-column flex-md-row gap-3 justify-content-center align-items-start">
				<Typography variant="bodyLg" className="text-left" style={{fontSize: "14px"}}>{t("sponsorship-modal-description")}.</Typography>
				<Button className="col-12 col-md-auto" onClick={() => setModalOpen(true)} label={t("sponsor-me")} bgColor="transparent" outline color={colors.secondary} borderColor="black" textColor="black" fontSizeSmall />
			</div>
		</div>
	)
}

export default SponsorMeSection
