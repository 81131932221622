import React, {useEffect, useState} from "react"
import {AppProps} from "next/app"
import "../styles/main.scss"
import CartContextProvider from "../provider/CartContextProvider"
import CheckoutContextProvider from "../provider/CheckoutContextProvider"
import RouteTransitionContextProvider from "../provider/RouteTransitionContextProvider"
import ProgressBar from "../components/routeTransition/ProgressBar"
import LayoutContextProvider from "../provider/LayoutContextProvider"
import CookieConsent from "../components/consent/CookieConsent"
import ModalContextProvider from "../provider/ModalContextProvider"
import Chat from "../components/HubSpot/chat"
import SearchContextProvider from "../provider/SearchContextProvider"
import DataContextProvider from "../provider/DataContextProvider"
import ToastMessageContestProvider from "../provider/ToastMessageContextProvider"
import AnalyticsContextProvider from "../provider/AnalyticsContextProvider"
import FirebaseContextProvider from "../provider/FirebaseContextProvider"
import {appWithTranslation} from "next-i18next"
import nextI18NextConfig from "../next-i18next.config.js"
import dynamic from "next/dynamic"
import {useRouter} from "next/router"
import {fbEvents} from "../config/facebook/events"
import OrganizationContextProvider from "../provider/OrganizationContextProvider"
import WishListContextProvider from "../provider/WishListContextProvider"

const StagingLogin = dynamic(() => import("../components/staging/StagingLogin"))

declare global {
	interface Window {
		fbq: any;
	}
}

const App = ({Component, pageProps}: AppProps) => {
	const [devAuthenticated, setDevAuthenticated] = useState<boolean>(process.env.NEXT_PUBLIC_ENVIRONMENT === "production")
	useEffect(() => {
		if (localStorage.getItem("devAuthenticated") === process.env.NEXT_PUBLIC_STAGING_PASSWORD) {
			setDevAuthenticated(true)
		}
	}, [])

	// Track data for FB page view
	useEffect(() => {
		if (process.env.NEXT_PUBLIC_ENVIRONMENT === "production") {
			try {
				// @ts-ignore
				if (typeof window !== "undefined" && window.fbq) {
					window.fbq("track", fbEvents.pageView)
				}
			} catch (e) {
				console.error(e)
			}
		}
	}, [])

	const handleAuthSubmit = values => {
		if (values.password === process.env.NEXT_PUBLIC_STAGING_PASSWORD) {
			setDevAuthenticated(values.password)
			localStorage.setItem("devAuthenticated", values.password)
		} else {
			// eslint-disable-next-line no-alert
			alert("Wrong password")
		}
	}

	// Store utm params in local storage

	const router = useRouter()
	useEffect(() => {
		const isUtm = router.asPath.includes("utm")
		if (isUtm) {
			window.localStorage.setItem("checkoutUtm", router.asPath)
		}
	}, [router.asPath])

	return (
		<>
			<OrganizationContextProvider>
				<AnalyticsContextProvider>
					<FirebaseContextProvider pageProps={pageProps}>
						<LayoutContextProvider>
							<ToastMessageContestProvider>
								<RouteTransitionContextProvider>
									<CartContextProvider>
										<CheckoutContextProvider>
											<WishListContextProvider>
												<DataContextProvider pageProps={pageProps}>
													<ModalContextProvider>
														<CookieConsent/>
														<Chat/>
														<SearchContextProvider>
															<ProgressBar/>
															<Component {...pageProps} />
														</SearchContextProvider>
													</ModalContextProvider>
												</DataContextProvider>
											</WishListContextProvider>
										</CheckoutContextProvider>
									</CartContextProvider>
								</RouteTransitionContextProvider>
							</ToastMessageContestProvider>
						</LayoutContextProvider>
					</FirebaseContextProvider>
				</AnalyticsContextProvider>
			</OrganizationContextProvider>
			{!devAuthenticated && <div style={{
				zIndex: 999999
			}} className={"position-fixed top-0 start-0 bottom-0 end-0 bg-white"}>
				<div className={"container mt-4"}>
					<div className={"row justify-content-center"}>
						<div className={"col-12 col-md-6 col-lg-4"}>
							<h1>Please login</h1>
							<StagingLogin onSubmit={values => handleAuthSubmit(values)}/>
						</div>
					</div>
				</div>
			</div>}
		</>
	)
}

export default appWithTranslation(App, nextI18NextConfig)
