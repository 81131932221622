import {useContext, useEffect, useState} from "react"
import {getPriceAfterDiscount, isOccasionBike} from "../utility/Helper"
import DataContext from "../context/DataContext"
import CheckoutContext from "../context/CheckoutContext"
import {useRouter} from "next/router"
import OrganizationContext from "../context/OrganizationContext"

const extractPriceFromProduct = (isSecondHand: boolean, product) => isSecondHand ? product.displayPrice : product.uvpPrice

export default function isePrice(product: any) {
	const router = useRouter()
	const isSecondHand = isOccasionBike(product)
	const {data} = useContext(CheckoutContext)
	const extractedPrice = extractPriceFromProduct(isSecondHand, product)

	const downPayment = data && data.downPayment && data?.downPayment[product?.id] ? parseFloat(data?.downPayment[product?.id]) : 0

	let afterDownPaymentPrice = null
	if (!isNaN(downPayment)) {
		afterDownPaymentPrice = (extractedPrice) - downPayment <= 0 ? 0 : extractedPrice - downPayment
	}

	const getNetPrice = () => downPayment > 0 ? afterDownPaymentPrice : (extractedPrice)

	const {organizationSlug, organizationInitialized} = useContext(OrganizationContext)
	const [onSale, setOnSale] = useState(false)
	const [hasOrganizationDiscount, setHasOrganizationDiscount] = useState(false)

	const [finalPriceWithDownPayment, setFinalPriceWithDownPayment] = useState(product.discount && !organizationSlug ? getPriceAfterDiscount(product.discount, getNetPrice()) : getNetPrice())
	const [finalPriceWithoutDownPayment, setFinalPriceWithoutDownPayment] = useState(product.discount && !organizationSlug ? getPriceAfterDiscount(product.discount, extractedPrice) : extractedPrice)

	const [discount, setDiscount] = useState(() => isSecondHand ? product.uvpPrice - product.displayPrice : (product.discount || 0))
	useEffect(() => {
		const discountValue = isSecondHand ? product.uvpPrice - product.displayPrice : organizationSlug ? product.customInstanceDiscounts[organizationSlug] ? Number(product.customInstanceDiscounts[organizationSlug]) : 0 : product.discount

		if (discount !== discountValue) {
			setDiscount(() => discountValue)
		}

		// If this is a custom instance, check if for this instance a discount applies
		if (organizationSlug && organizationInitialized && product.customInstanceDiscounts) {
			setOnSale(() => Boolean(product.customInstanceDiscounts[organizationSlug]) || Boolean(product.discount))
			setHasOrganizationDiscount(() => Boolean(product.customInstanceDiscounts[organizationSlug]))
			setFinalPriceWithDownPayment(() => getPriceAfterDiscount(product.customInstanceDiscounts[organizationSlug], getNetPrice()))
			setFinalPriceWithoutDownPayment(() => getPriceAfterDiscount(product.customInstanceDiscounts[organizationSlug], extractedPrice))
			// If not, check if a regular instance discount applies
		} else if (organizationInitialized) {
			setOnSale(Boolean(product.discount))
			const netPrice = getNetPrice()
			setFinalPriceWithDownPayment(() => discountValue ? netPrice - discountValue : netPrice)
			setFinalPriceWithoutDownPayment(() => discountValue ? extractedPrice - discountValue : extractedPrice)
		}
	}, [organizationSlug, organizationInitialized, downPayment, router.asPath, product.slug])

	return {onSale, finalPriceWithDownPayment, finalPriceWithoutDownPayment, organizationSlug, downPayment, discount, hasOrganizationDiscount}
}
