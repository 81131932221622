import {brands} from "./TopBrands"
import {topCategoriesMegaMenuMBP} from "./TopCategories"
import {fields, initialValues, validationSchema} from "../components/forms/OtherInstancesNewsLetter"
import {filterUrl} from "../components/filter/FilterUrl"

const myBabyPlanInstanceId = process.env.NEXT_PUBLIC_FORM_MYBABYPLAN
const myMusicPlanInstanceId = process.env.NEXT_PUBLIC_FORM_MYMUSICPLAN

export const heights = [
	{title: "145-150cm", slug: "145-150-cm"},
	{title: "150-155cm", slug: "150-155-cm"},
	{title: "155-160cm", slug: "155-160-cm"},
	{title: "160-165cm", slug: "160-165-cm"},
	{title: "165-170cm", slug: "165-170-cm"},
	{title: "170-175cm", slug: "170-175-cm"},
	{title: "175-180cm", slug: "175-180-cm"},
	{title: "180-185cm", slug: "180-185-cm"},
	{title: "185-190cm", slug: "185-190-cm"},
	{title: "190-195cm", slug: "190-195-cm"},
	{title: "195-200cm", slug: "195-200-cm"}
]

export const tabItems = [
	{
		href: process.env.NEXT_PUBLIC_CUSTOM_INSTANCE ? "instance.mybikeplan.ch/" : "https://www.mybikeplan.ch/",
		iconSrc: "/assets/icons/mbp.svg",
		mobileIconSrc: "/assets/icons/bike-nobg.svg",
		title: "MyBikePlan",
		primaryColor: "#FEC04D",
		bgColor: "#f9f3ec",
		checkmarkSrc: "/assets/icons/checkmark.svg",
		// When we provide a Null megaTitle it means that we will now show a dropDown when hovering this element
		megaTitle: "Alle Modelle", // Always required if you want a dropdown
		megaMenuTopBrands: brands, // Only required when StoreFront main megamenu has top brands in it
		megaMenuTopCategories: topCategoriesMegaMenuMBP,
		heights
	},
	{
		href: process.env.NEXT_PUBLIC_CUSTOM_INSTANCE ? "https://instance.myhomeplan.ch/" : "https://www.myhomeplan.ch/",
		iconSrc: "/assets/icons/mhp.svg",
		mobileIconSrc: "/assets/icons/sofa.svg",
		title: "MyHomePlan",
		primaryColor: "#6E9060",
		bgColor: "#e9f5e4",
		checkmarkSrc: "/assets/icons/checkmark.svg",
		megaTitle: null,
		megaMenuTopBrands: null,
		megaMenuTopCategories: null,
		heights: null
	},
	{
		href: "https://www.myplans.ch/mygolfplan",
		iconSrc: "/assets/icons/MyGolfPlan.svg",
		mobileIconSrc: "/assets/icons/golf.png",
		title: "MyGolfPlan",
		primaryColor: "#F9564F",
		bgColor: "#f8f8f8",
		checkmarkSrc: "/assets/icons/checkmark.svg",
		description: "mygolfplan-description"
	},
	{
		href: null,
		iconSrc: "/assets/icons/MyBabyPlan.svg",
		mobileIconSrc: "/assets/icons/baby_bottle.png",
		title: "MyBabyPlan",
		primaryColor: "#fac1bb",
		bgColor: "#f8f8f8",
		ComingSoon: true,
		checkmarkSrc: "/assets/icons/iconCategories/mail-add.svg",
		newsletterFormData: {
			title: "MyBabyPlan",
			fields,
			validationSchema,
			initialValues,
			config: {
				formId: process.env.NEXT_PUBLIC_NEWSLETTER_FORM_ID_MYBABYPLAN,
				instanceId: {
					de: myBabyPlanInstanceId,
					fr: myBabyPlanInstanceId
				},

				oploadFile: false
			},
			type: "newCustomer",
			newInstance: true
		},
		description: "Bald findest Du hier unseren neuen Shop mit tollen Produkten für Babys und Kleinkinder. Melde Dich für die E-Mail-Liste an, um benachrichtigt zu werden, sobald wir online gehen!"
	},
	{
		href: null,
		iconSrc: "/assets/icons/MyMusicPlan.svg",
		mobileIconSrc: "/assets/icons/music.png",
		title: "MyMusicPlan",
		primaryColor: "#8A6552",
		bgColor: "#f8f8f8",
		ComingSoon: true,
		checkmarkSrc: "/assets/icons/mail-add.svg",
		newsletterFormData: {
			title: "MyMusicPlan",
			fields,
			validationSchema,
			initialValues,
			config: {
				formId: process.env.NEXT_PUBLIC_NEWSLETTER_FORM_ID_MYBABYPLAN,
				instanceId: {
					de: myMusicPlanInstanceId,
					fr: myMusicPlanInstanceId
				},
				uploadFile: false
			},
			type: "newCustomer",
			newInstance: true
		},
		description: "Bald findest Du hier unseren neuen Shop mit tollen Musikinstrumenten. Melde Dich für die E-Mail-Liste an, um benachrichtigt zu werden, sobald wir online gehen!"
	}

]

export const content = {
	sbb: {
		companyName: "SBB",
		tabIcon: "/assets/icons/sbb_logo_red_bg.svg",
		companyIcon: "/assets/icons/SBB-logo.svg",
		loginPopup: {
			logo: "/assets/icons/sbb_logo_red_bg.svg",
			title: "log_benefits",
			subTitle: "login_popup_subtitle",
			bulletPoints: ["login_popup_bulltepoint1", "login_popup_bulltepoint2", "login_popup_bulltepoint3"]
		},
		listingItem: {
			text: "instances_exclusive"
		},
		banner: {
			bgColor: "#ed0200",
			textColor: "white",
			logo: "/assets/icons/sbb_logo.svg",
			header: "for_instances_employees",
			subHeader: "for_instances_employees_subheader"
		},
		stickyBar: {
			text: "Herzlich willkommen, SBB-Mitarbeiter/in!",
			buttonCTA: "E-Bikes entdecken!",
			buttonLink: t => filterUrl([], null, null, t)
		},
		home: {
			subTitle: "welcome_instances",
			text: "instances_home_text"
		}
	},
	tcs: {
		companyName: "TCS",
		tabIcon: "/assets/icons/tcs-logo.png",
		companyIcon: "/assets/icons/tcs-logo.png",
		loginPopup: {
			logo: "/assets/icons/tcs-logo.png",
			title: "log_benefits",
			subTitle: "login_popup_subtitle",
			bulletPoints: ["login_popup_bulltepoint1", "login_popup_bulltepoint2", "login_popup_bulltepoint3"]
		},
		listingItem: {
			text: "instances_exclusive"
		},
		banner: {
			bgColor: "#ffeb02",
			textColor: "dark",
			logo: "/assets/icons/tcs-logo.png",
			header: "for_instances_employees",
			subHeader: "for_instances_employees_subheader"
		},
		stickyBar: {
			text: "Herzlich willkommen, TCS-Mitarbeiter/in!",
			buttonCTA: "E-Bikes entdecken!",
			buttonLink: t => filterUrl([], null, null, t)
		},
		home: {
			subTitle: "welcome_instances",
			text: "instances_home_text"
		},
		anyEmailAllowed: true
	},
	axpo: {
		companyName: "AXPO",
		tabIcon: "/assets/icons/axpo-logo.png",
		companyIcon: "/assets/icons/axpo-logo.png",
		loginPopup: {
			logo: "/assets/icons/axpo-logo.png",
			title: "log_benefits",
			subTitle: "login_popup_subtitle",
			bulletPoints: ["login_popup_bulltepoint1", "login_popup_bulltepoint2", "login_popup_bulltepoint3"]
		},
		listingItem: {
			text: "instances_exclusive"
		},
		banner: {
			bgColor: "#ff5d64",
			textColor: "dark",
			logo: "/assets/icons/axpo-logo.png",
			header: "for_instances_employees",
			subHeader: "for_instances_employees_subheader"
		},
		stickyBar: {
			text: "Herzlich willkommen, AXPO-Mitarbeiter/in!",
			buttonCTA: "E-Bikes entdecken!",
			buttonLink: t => filterUrl([], null, null, t)
		},
		home: {
			subTitle: "welcome_instances",
			text: "instances_home_text"
		}
	},
	grape: {
		companyName: "grape",
		tabIcon: "/assets/icons/grape-logo.png",
		companyIcon: "/assets/icons/grape-logo.png",
		loginPopup: {
			logo: "/assets/icons/grape-logo.png",
			title: "log_benefits",
			subTitle: "login_popup_subtitle",
			bulletPoints: ["login_popup_bulltepoint1", "login_popup_bulltepoint2", "login_popup_bulltepoint3"]
		},
		listingItem: {
			text: "instances_exclusive"
		},
		banner: {
			bgColor: "#7b1bff",
			textColor: "dark",
			logo: "/assets/icons/grape-logo.png",
			header: "for_instances_employees",
			subHeader: "for_instances_employees_subheader"
		},
		stickyBar: {
			text: "Herzlich willkommen, grape-Mitarbeiter/in!",
			buttonCTA: "E-Bikes entdecken!",
			buttonLink: t => filterUrl([], null, null, t)
		},
		home: {
			subTitle: "welcome_instances",
			text: "instances_home_text"
		},
		anyEmailAllowed: true
	},
	zurich: {
		companyName: "zurich",
		tabIcon: "/assets/icons/zurich-logo.svg",
		companyIcon: "/assets/icons/zurich-logo.svg",
		loginPopup: {
			logo: "/assets/icons/zurich-logo.svg",
			title: "log_benefits",
			subTitle: "login_popup_subtitle",
			bulletPoints: ["login_popup_bulltepoint1", "login_popup_bulltepoint2", "login_popup_bulltepoint3"]
		},
		listingItem: {
			text: "instances_exclusive"
		},
		banner: {
			bgColor: "#ebebeb",
			textColor: "dark",
			logo: "/assets/icons/zurich-logo.svg",
			header: "for_instances_employees",
			subHeader: "for_instances_employees_subheader"
		},
		stickyBar: {
			text: "Herzlich willkommen, Zurich-Mitarbeiter/in!",
			buttonCTA: "E-Bikes entdecken!",
			buttonLink: t => filterUrl([], null, null, t)
		},
		home: {
			subTitle: "welcome_instances",
			text: "instances_home_text"
		}
	},
	uhbs: {
		companyName: "Universitätsspital Basel",
		tabIcon: "/assets/icons/uhbs-logo.svg",
		companyIcon: "/assets/icons/uhbs-logo.svg",
		loginPopup: {
			logo: "/assets/icons/uhbs-logo.svg",
			title: "log_benefits",
			subTitle: "login_popup_subtitle",
			bulletPoints: ["login_popup_bulltepoint1", "login_popup_bulltepoint2", "login_popup_bulltepoint3"]
		},
		listingItem: {
			text: "instances_exclusive"
		},
		banner: {
			bgColor: "#ebebeb",
			textColor: "dark",
			logo: "/assets/icons/uhbs-logo.svg",
			header: "for_instances_employees",
			subHeader: "for_instances_employees_subheader"
		},
		stickyBar: {
			text: "Herzlich willkommen, Universitätsspital Basel-Mitarbeiter/in!",
			buttonCTA: "E-Bikes entdecken!",
			buttonLink: t => filterUrl([], null, null, t)
		},
		home: {
			subTitle: "welcome_instances",
			text: "instances_home_text"
		}
	}, gleason: {
		companyName: "Gleason",
		tabIcon: "/assets/icons/gleason-logo.png",
		companyIcon: "/assets/icons/gleason-logo.png",
		loginPopup: {
			logo: "/assets/icons/gleason-logo.png",
			title: "log_benefits",
			subTitle: "login_popup_subtitle",
			bulletPoints: ["login_popup_bulltepoint1", "login_popup_bulltepoint2", "login_popup_bulltepoint3"]
		},
		listingItem: {
			text: "instances_exclusive"
		},
		banner: {
			bgColor: "#ebebeb",
			textColor: "dark",
			logo: "/assets/icons/gleason-logo.png",
			header: "for_instances_employees",
			subHeader: "for_instances_employees_subheader"
		},
		stickyBar: {
			text: "Herzlich willkommen, Gleason-Mitarbeiter/in!",
			buttonCTA: "E-Bikes entdecken!",
			buttonLink: t => filterUrl([], null, null, t)
		},
		home: {
			subTitle: "welcome_instances",
			text: "instances_home_text"
		},
		email: "gleason.ch"
	},
	valora: {
		companyName: "Valora",
		tabIcon: "/assets/icons/valora-logo.png",
		companyIcon: "/assets/icons/valora-logo.png",
		loginPopup: {
			logo: "/assets/icons/valora-logo.png",
			title: "log_benefits",
			subTitle: "login_popup_subtitle",
			bulletPoints: ["login_popup_bulltepoint1", "login_popup_bulltepoint2", "login_popup_bulltepoint3"]
		},
		listingItem: {
			text: "instances_exclusive"
		},
		banner: {
			bgColor: "#ebebeb",
			textColor: "dark",
			logo: "/assets/icons/valora-logo.png",
			header: "for_instances_employees",
			subHeader: "for_instances_employees_subheader"
		},
		stickyBar: {
			text: "Herzlich willkommen, Valora-Mitarbeiter/in!",
			buttonCTA: "E-Bikes entdecken!",
			buttonLink: t => filterUrl([], null, null, t)
		},
		home: {
			subTitle: "welcome_instances",
			text: "instances_home_text"
		},
		email: "valora.ch"
	},
	css: {
		companyName: "CSS",
		tabIcon: "/assets/icons/css-logo.png",
		companyIcon: "/assets/icons/css-logo.png",
		loginPopup: {
			logo: "/assets/icons/css-logo.png",
			title: "log_benefits",
			subTitle: "login_popup_subtitle",
			bulletPoints: ["login_popup_bulltepoint1", "login_popup_bulltepoint2", "login_popup_bulltepoint3"]
		},
		listingItem: {
			text: "instances_exclusive"
		},
		banner: {
			bgColor: "#ebebeb",
			textColor: "dark",
			logo: "/assets/icons/css-logo.png",
			header: "for_instances_employees",
			subHeader: "for_instances_employees_subheader"
		},
		stickyBar: {
			text: "Herzlich willkommen, CSS-Mitarbeiter/in!",
			buttonCTA: "E-Bikes entdecken!",
			buttonLink: t => filterUrl([], null, null, t)
		},
		home: {
			subTitle: "welcome_instances",
			text: "instances_home_text"
		}
	}
}

export const otherInstancesCategoryTitles = [
	"Alle E-Bikes", "All E-Bikes", "Alle Modelle"
]

export const getMyBikePlanUrl = (organisation:string, category:string = "-", height:string = "-", brand:string = "-", isAccessoryPage: boolean = false) => {
	let baseUrl
	switch (process.env.NEXT_PUBLIC_ENVIRONMENT) {
		case "development":
		case "preview":
		case "local":
			baseUrl = "https://staging.mybikeplan.ch"
			break
		case "production":
			if (process.env.NEXT_PUBLIC_CUSTOM_INSTANCE) {
				baseUrl = `https://${organisation}.mybikeplan.ch/`
				break
			}

			baseUrl = "https://www.mybikeplan.ch"
			break
		default:
			baseUrl = "https://www.mybikeplan.ch"
			break
	}

	return isAccessoryPage ? `${baseUrl}/zubehor/type/-/marke/${category}/preisbereich/-/sortieren/aufsteigend` :
		`${baseUrl}/alle-modelle-e-bike-schweiz/kategorie/${category}/rahmenvarianten/-/batterie-kapazitat/-/max-gewicht/-/zubehor/-/geschwindigkeit/-/marke/${brand}/motor-marke/-/farbe/-/koerpergroesse/${height}/preisbereich/-/verfugbar/-/suchen/-/sortieren/preis/relevanz/-`
}

