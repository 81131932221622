import React, {useContext} from "react"
import DataContext from "../../../context/DataContext"
import {useTranslation} from "next-i18next"
import BikesCollectionItem from "../../bikesCollection/BikesCollectionItem/BikesCollectionItem"
import Typography from "../../reusable/Typography/Typography"

function MiniPLP() {
	const {customBikeRecommendations} = useContext(DataContext)
	const {t} = useTranslation("common")
	if (customBikeRecommendations?.length) {
		return (<div style={{marginBottom: "4rem"}} className="container py-4">
			<Typography variant="bodySmBold" semanticTag="h1" style={{fontSize: "28px", lineHeight: "36px", paddingBottom: "24px"}} >{t("recommended-for-you-label")}</Typography>
			<div className={`${customBikeRecommendations.length >= 4 ? " miniPLP-container" : " d-none d-md-flex gap-4 relative justify-content-start"}`}>
				{customBikeRecommendations.length ? customBikeRecommendations.map((bike, idx) => <BikesCollectionItem key={idx} cardWidth="240px" bike={bike} isTwoCardsView={true} />) : null}
			</div>
			<div className=" miniPLP-container-mobile">
				{customBikeRecommendations.length ? customBikeRecommendations.map((bike, idx) => <BikesCollectionItem key={idx} bike={bike} cardWidth={"163px"} isTwoCardsView={true} isRowForMobile={true}/>) : null}
			</div>
		</div>
		)
	}

	return null
}

export default MiniPLP
