import React from "react"
import Typography from "../reusable/Typography/Typography"
import Price from "../bikesCollection/BikesCollectionItem/Price"
import TotalPrice from "../bikesCollection/BikesCollectionItem/TotalPrice"
import {useTranslation} from "next-i18next"
import Link from "next/link"
import Image from "next/image"
import usePrice from "../../hooks/usePrice"
import ProductAvailableStatus from "../bikesCollection/BikesCollectionItem/ProductAvailabelStatus"
import ComparisonCheckboxButton from "../bikesCollection/BikesCollectionItem/ComparisonCheckboxButton"
import CollectionItemBadge from "../bikesCollection/BikesCollectionItem/CollectionItemBadge"

type Props = {
  product: any,
  regularPrice?: string,
  secondHandPrice?: number,
  isSecondHand?: boolean,
  bike?: any,
  onHandleClosePopup?: ()=> void
	hasBadge?: boolean
	hideComparison?: boolean
}

const CompatibleAccessory: React.FC<Props> = ({product, hasBadge, bike, regularPrice, secondHandPrice, isSecondHand, onHandleClosePopup = null, hideComparison = false}) => {
	const {t} = useTranslation("common")
	const productUrl = `/${t("produkt")}/${product.slug}`
	const {onSale, finalPriceWithoutDownPayment} = usePrice(product)

	return (
		<Link href={productUrl}>
			<a className={"link-reset"} onClick={onHandleClosePopup}>
				<div>
					<div className="position-absolute mt-2">
						{hasBadge && <div
							className="ps-0 d-flex align-items-start z-index position-absolute"
							style={{marginTop: -25, marginLeft: 5, zIndex: 100}}>
							<CollectionItemBadge
								inStock={product.stock_status === "instock"}
								onSale={onSale} fontSizeXSm
							/>
						</div>}
					</div>
					<div className="mt-3 mt-md-0" style={{
						borderRadius: 16,
						padding: 12,
						border: "1px solid #DEE2E6",
						backgroundColor: "#fff"
					}}>
						<div className="d-flex align-items-start pe-2 position-relative" style={{minHeight: 80}}>
							<div className="col-auto">
								<Image src={product.images[0]?.src} alt="bike accessory" height={72} width={72} objectFit={"contain"}/>
							</div>
							<div className="ps-2 col-9 d-flex flex-column justify-content-center text-start text-wrap text-truncate">
								<div className="d-flex align-items-start gap-1">
									<Typography style={{maxWidth: 160, width: "90%"}} className="multiline-truncate pe-2 mb-1 d-inline-block " variant={"bodyXSmExtraBold"}> {product.name}</Typography>
									<div className="mt-1 position-absolute" style={{right: 5, zIndex: 1000}}>
										<ProductAvailableStatus stockStatus={product?.stock_status} withHoverEffect={true}/>
									</div>
								</div>
								<div className={"col-12 px-0 mt-2 mb-1"}>
									<Price
										isAccessoryPrice={true}
										price={parseFloat(product.uvpPrice || product.price)}
										onSale={onSale} discountedPrice={finalPriceWithoutDownPayment} priceFontSize={24} />
									<TotalPrice isAccessoryPrice={true} price={parseFloat(product.uvpPrice || product.price)} onSale={onSale} priceVariant="bodyXSm"
										discountedPrice={finalPriceWithoutDownPayment}/>
								</div>
							</div>
						</div>
						{hideComparison ? null : <div className="col-12 mt-1">
							<ComparisonCheckboxButton slug={product.slug}/>
						</div>}
					</div>
				</div>
			</a>
		</Link>
	)
}

export default CompatibleAccessory
