import React, {useContext, useEffect, useMemo, useState} from "react"
import WishListContext from "../context/WishListContext"
import {BikeType} from "../utility/Types"
import FirebaseContext from "../context/FirebaseContext"
import useCache from "../hooks/useCache"
import {fetchPost} from "../firebaseAdmin/fetchPost"

type Props = {
    children: React.ReactNode
}

function WishListContextProvider({children}:Props) {
	const [wishList, setWishList] = useState<BikeType[]>([])
	const {user} = useContext(FirebaseContext)
	const [wishListFetchedFromFirestore, setwishListFetchedFromFirestore] = useState<boolean>(false)

	const wishListUrl = useMemo(() => {
		let url = null

		if (!wishListFetchedFromFirestore && user?.uid) {
			url = `/api/firestore/getWishList?uid=${user.uid}`
		}

		return url
	}, [wishListFetchedFromFirestore, user])

	const storeWishList = async newWishList => {
		try {
			const res = await fetchPost("/api/firestore/createWishList", {uid: user.uid, wishList: newWishList})
			return res.status === 200
		} catch (error) {
			console.log(error)
		}
	}

	const {data, error, isLoading: isFetchingFromFirestore} = useCache(wishListUrl)

	const addToWishList = async (bike: BikeType) => {
		const list = wishList || []
		const bikeAlreadyExists = getWishListItemBySlug(bike.slug)

		if (bikeAlreadyExists) {
			await deleteWishListItemBySlug(bike.slug)
			return null
		}

		list.push(bike)
		setWishList(list)
		const storedSuccessfully = await storeWishList(list)

		if (!storedSuccessfully) {
			console.error("Something went wrong with firestore")
		}
	}

	const clearWishList = async () => {
		setWishList(() => [])

		const storedSuccessfully = await storeWishList([])

		if (!storedSuccessfully) {
			console.error("Something went wrong with firestore")
		}
	}

	const deleteWishListItemBySlug = async (slug: string) => {
		const newList = wishList.filter(item => !item.slug.includes(slug))
		setWishList(() => newList)
		const storedSuccessfully = await storeWishList(newList)

		if (!storedSuccessfully) {
			console.error("Something went wrong with firestore")
		}
	}

	const getWishListItemBySlug = (slug: string):BikeType => {
		const list = wishList || []
		const result = list?.find(item => item.slug.includes(slug)) || null
		return 	result
	}

	useEffect(() => {
		if (data && data?.wishList && !error) {
			setWishList(() => data.wishList)
			setwishListFetchedFromFirestore(true)
		}

		if (error) {
			setWishList(() => [])
			setwishListFetchedFromFirestore(true)
		}
	}, [data, error])
	return (
		<WishListContext.Provider value={{wishList, setWishList, addToWishList, deleteWishListItemBySlug, clearWishList, isFetchingFromFirestore, getWishListItemBySlug}}>
			{children}
		</WishListContext.Provider>
	)
}

export default WishListContextProvider
