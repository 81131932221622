import Typography from "../../reusable/Typography/Typography"
import {Slides} from "../../reusable/components/Slides"
import CompatibleAccessory from "../../bikeDetails/CompatibleAccessory"
import React, {useEffect, useState} from "react"
import {useTranslation} from "next-i18next"
import ComparisonPLPFixedComponent from "../../bikesCollection/ComparisonPLPFixedComponent"

type Props = {
	hasComparisonSection?: boolean
	titlePosition?: string
	hasBorderBottom?: boolean
}

const RecentlyViewedProducts: React.FC<Props> = ({hasComparisonSection = true, titlePosition, hasBorderBottom}) => {
	const [recentlyViewedProducts, setRecentlyViewedProducts] = useState<any[]>([])

	const {t} = useTranslation("common")
	useEffect(() => {
		const storedRecentlyViewed = JSON.parse(localStorage.getItem("recentlyViewedProducts")) || []
		setRecentlyViewedProducts(storedRecentlyViewed)
	}, [])

	useEffect(() => {
		const handleStorageChange = () => {
			const storedRecentlyViewed = JSON.parse(localStorage.getItem("recentlyViewedProducts")) || []
			setRecentlyViewedProducts(storedRecentlyViewed)
		}

		window.addEventListener("storage", handleStorageChange)
		return () => {
			window.removeEventListener("storage", handleStorageChange)
		}
	}, [])
	return (
		recentlyViewedProducts.length > 0 ?
			<div>
				<div className={"d-none d-md-block container px-0 col-12 mb-5 mt-5"} style={{minHeight: 220}}>
					<div className={"row recently-viewed-products "}>
						<div className={"col-12"}>
							<div className={titlePosition ? titlePosition : "text-center"}>
								<Typography variant="heading2Black" className="mb-4 pb-3" semanticTag="h2">
									{t("recently-viewed-products-title")}
								</Typography>
								{recentlyViewedProducts.length > 4 ?
									<div className="row px-0 compatible-accessories">
										<Slides dotsPositionY={-66} arrows arrowsPositionX={-3}
											dots slidesToShow={4}>
											{recentlyViewedProducts.map((product, index) => {
												return (
													<div key={product.id + index} className={"h-100 col-auto px-2 compatible-accessories-item"} style={{background: "#F8F8F8", borderRadius: 24}}>
														<CompatibleAccessory key={product.id} product={product} bike={product}/>
													</div>
												)
											})}
										</Slides>
									</div>	:
									<div className={`d-flex ${recentlyViewedProducts.length === 4 ? "justify-content-between gap-2" : "justify-content-center gap-3"} `}>
										{recentlyViewedProducts.map((product, index) => {
											return (
												<div className="col-3" key={product.id + index} >
													<CompatibleAccessory product={product} bike={product}/>
												</div>
											)
										})}
									</div>
								}
							</div>
						</div>
					</div>
				</div>
				<div className="d-md-none">
					<Typography className="mb-4 pb-1" variant="bodyLgBlack" style={{fontSize: 28, paddingLeft: "12px"}}>{t("recently-viewed-products-title")}</Typography>
					<div>
						{
							recentlyViewedProducts.length > 1 ?
								<Slides centerMode centerModePadding={{left: 0, bottom: 0, right: 25}}>
									{recentlyViewedProducts.map((product, idx) => {
										if (idx <= 2) {
											return (
												<div key={product.id} className="px-2">
													<CompatibleAccessory hasBadge key={product.id} product={product} bike={product}/>
												</div>
											)
										}

										return null
									})}
								</Slides> :
								recentlyViewedProducts.map((product, idx) => {
									if (idx <= 2) {
										return (
											<div className="container">
												<CompatibleAccessory hasBadge key={product.id} product={product} bike={product}/>
											</div>
										)
									}

									return null
								})
						}</div>
				</div>
				{hasComparisonSection &&
					<div className="col-12 col-md-10 mt-md-4 container">
						<ComparisonPLPFixedComponent sticky={false}/>
					</div>}
				{hasBorderBottom &&
					<div style={{height: 1, backgroundColor: "#DEE2E6", margin: "24px 0px"}} className="col-12 mb-5"/>}
			</div> :
			null
	)
}

export default RecentlyViewedProducts
